import { List, FilterForm, SelectInput, useDataProvider, ReferenceInput, usePermissions,EditButton, useListController, TopToolbar, ExportButton, Datagrid, TextField, ReferenceField, FunctionField, Button } from 'react-admin';
import { Box, Typography, Stack } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useHideModifierLabel } from '../Tools/ModifyHeader/ModifyHeader';
import { orderExporter, orderExporterPDF } from '../Tools/ExportExcel/exportFishPrice';

const Empty = () => {
    let data = useListController();
    return (
        <Box textAlign="center" m={12}>
            <Typography variant="h4" paragraph>
                Pas encore de Tarifs.
            </Typography>
            <Typography variant="body1">
                Voulez-vous en créer un ?
            </Typography>
            <Button href={"#/fish_prices/create"} label="Créer" />
        </Box>
    );
}

const dateRenderer = (record) => {
    const startDate = moment(record.startDate).format('YYYY');
    const endDate = moment(record.endDate).format('YYYY');
    return `${startDate} / ${endDate}`;
};

const prepareUserChoices = async (dataProvider) => {
    const { data: users } = await dataProvider.getList('users', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'lastName', order: 'ASC' },
    });

    return users.map(user => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName} ${user.structureName ? '/ ' + user.structureName : ''}`
    }));
};

const prepareSpecieFishChoices = async (dataProvider) => {
    const { data: species } = await dataProvider.getList('specie_fishes', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'name', order: 'ASC' },
    });

    const uniqueSpeciesMap = new Map();
    species.forEach(specie => {
        if (!uniqueSpeciesMap.has(specie.name)) {
            uniqueSpeciesMap.set(specie.name, specie);
        }
    });

    return species.map(specie => ({
        id: specie.name,
        name: specie.x
    }));
};

const PostListActions = () => {
    let data = useListController();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const [campaignValue, setCampaignValue] = useState(null);
    const [userChoices, setUserChoices] = useState([]);
    const [specieChoices, setSpecieChoices] = useState([]);

    const handleSelectChange = (event) => {
        setCampaignValue(event.target.value);
    };

    useEffect(() => {
        if (permissions?.includes('ROLE_ADMIN')) {
            prepareUserChoices(dataProvider).then(setUserChoices);
        }
        prepareSpecieFishChoices(dataProvider).then(setSpecieChoices);
    }, [dataProvider, permissions]);

    const postFiltersSpecies = [
        <ReferenceInput
            source="specieFish"
            reference="specie_fishes"
            filter={{ nonemptystage: true }}
            filterToQuery={searchText => ({ stage: searchText })}
            perPage={100}
            alwaysOn
        >
            <SelectInput optionText={"stage"} label="Espèce" fullWidth />
        </ReferenceInput>
    ];

    const postFiltersUsers = [
        <SelectInput
            source="user"
            choices={userChoices}
            optionValue="id"
            optionText="name"
            label="Nom/Prénom/Organisme"
            fullWidth
            alwaysOn
        />
    ];

    const postFilters = [
        <ReferenceInput source="campaign" label="Campagne" reference="campaigns" alwaysOn>
            <SelectInput
                emptyText="Toutes les campagnes"
                optionText={dateRenderer}
                label="Campagne"
                fullWidth
                defaultValue=""
                onChange={handleSelectChange}
            />
        </ReferenceInput>
    ];

    return (
        <TopToolbar>
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
                {permissions !== undefined && permissions?.includes('ROLE_ADMIN') ? <span style={{ display: 'contents' }} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFiltersUsers} /></span> : ""}
                <span style={{ display: 'contents' }} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFilters} /></span>
                <span style={{ display: 'contents' }} ><FontAwesomeIcon icon={faFilter} className="filter-icon" style={{ marginRight: '20px' }} /><FilterForm filters={postFiltersSpecies} /></span>
                <div style={{ display: 'flex' }}>
                    <Button href={"#/fish_prices/create"} label='Créer' />
                    <div style={{ marginLeft: 10 }}>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#38a9b4', height: 30, width: 'auto', border: 'none', margin: '0 20px 0 0', display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: 10 }}>Exporter</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu" style={{ padding: 10, border: '1px solid #f2f2f2', width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <ExportButton label='Exporter (Excel)' onClick={() => orderExporter(data.data, null, dataProvider, campaignValue)} />
                                    <ExportButton label='Exporter (PDF)' onClick={() => orderExporterPDF(data.data, null, dataProvider, campaignValue)} />
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Stack>
        </TopToolbar>
    );
}

const FishPriceList = () => {
    useHideModifierLabel();
    let data = useListController();
    return (
        <div>
            <h1 className={"titleName"}>{data !== undefined ? data.defaultTitle : ''}</h1>
            <List actions={<PostListActions />} exporter={false} empty={<Empty />} >
                <Datagrid>
                    <TextField source="originId" label="#" />
                    <ReferenceField link={false} label="Campagne" source="campaign" reference="campaigns">
                        <FunctionField render={record => record ? `${moment(record.startDate).format('YYYY')} / ${moment(record.endDate).format('YYYY')}` : ''} />
                    </ReferenceField>
                    <ReferenceField link={false} label="Espèce" source="specieFish" reference="specie_fishes">
                        <FunctionField render={record => record ? `${record.stage}` : ''} />
                    </ReferenceField>
                    <TextField source="disposalFeeCollector" label="Vendu au collecteur (en € HT/kg)" />
                    <TextField source="purchaseRateCollector" label="Acheté au collecteur (en € HT/kg)" />
                    <TextField source="costPrice" label="Empoissonnage autoproduit ou gardé" />
                    <EditButton label="MODIFIER" />
                </Datagrid>
            </List>
        </div>
    );
}
export default FishPriceList;
